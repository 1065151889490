import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CloudVideo from "../layouts/cloudvideo";

const GalleryView = ({ currentItem, currentItemIndex, currentExhibit, showPreviousItem, showNextItem, handleCloseGallery }) => {
  return (
    <>
    <div className="relative h-screen flex flex-col items-center justify-center">
      {currentItem && (
        <div className="flex flex-row justify-center overflow-hidden w-full scrollbar-hide px-[20px] lg:px-[80px]">
          <div className="flex-shrink-0 my-auto">
            {currentItem._type === "mainImage" && (
              <div>
                <GatsbyImage
                  image={currentItem.asset.gatsbyImageData}
                  key={currentItem._key}
                  alt={currentItem.alt}
                  className="w-[400px] h-[300px] lg:h-[800px] lg:w-[1000px] object-contain my-auto"
                />
              </div>
            )}
            {currentItem._type === "video" && (
              <CloudVideo
                className="w-auto h-[300px] lg:h-[800px] lg:w-auto object-contain"
                cloudVideoUrl={currentItem.url}
                CloudVideoAlt={currentItem.title}
              />
            )}
          </div>
        </div>
      )}

    </div>
          <div className="flex flex-row justify-between cursor-pointer font-caslon sticky bottom-7 lg:bottom-4 px-[20px] lg:px-[80px]">
          <div onClick={handleCloseGallery}>Close Gallery</div>
          <div className="flex flex-row gap-[30px] lg:gap-[40px]">
            <button onClick={showPreviousItem} className={currentItemIndex === 0 ? "text-gray-400" : "text-black"}>Previous</button>
            <button onClick={showNextItem} className={currentItemIndex === currentExhibit.videoImgGall.length - 1 ? "text-gray-400" : "text-black"}>Next</button>
          </div>
        </div>
        </>
  );
};

export default GalleryView;
