import * as React from "react";
import { graphql } from "gatsby";
import LayoutVideo from "../../components/layouts/layoutVideo";
import Seo from "../../components/seo";
import Container from "../../components/layouts/container";
import ExhibitList from "../../components/exhibitions/exhibitList";

const ExhibitIndex = ({ data }) => {
  if (!data) {
    return null;
  }
  const { site, exhibit } = data;

  const exhibition = exhibit.nodes[0];

  return (
    <>
      <main>
        <LayoutVideo>
          <Seo
            title={site.title}
            description={site.description}
            keywords={site.keywords}
          />
          <Container>
            <ExhibitList exhibit={exhibition} /> 
          </Container>
        </LayoutVideo>
      </main>
    </>
  );
};

export const query = graphql`
  query {
    site: sanitySiteSettings {
      title
      keywords
      description
      id
    }
    exhibit: allSanityExhibitionsIndex {
      nodes {
        name
        id
        exhibitArray {
          title
          _key
          id
          slug {
          current
        }
          videoImgGall {
            ... on SanityVideo {
              _key
              _type
              url
              title
              _rawBodyPortableText
            }
            ... on SanityMainImage {
              _key
              _type
              asset {
                altText
                id
                height
                width
                url
              gatsbyImageData
              }
              alt
            }
          }
        }
      }
    }
  }
`;

export default ExhibitIndex;
