import React from "react";
import Footer from "../navigation/footer";
import Navigation from "../navigation/nav";
import ResNav from "../navigation/resNav";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useInView } from "react-intersection-observer";
import useReadingProgress from "../../lib/hooks";

const LayoutVideo = ({ children }) => {
  const info = useStaticQuery(graphql`
    {
      site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
        title
        description
        keywords
      }
    }
  `);

  const siteTitle = info.site.title;
  // console.log(siteTitle);

  const completion = useReadingProgress();
  const { ref: workRef, inView: myElementIsVisible } = useInView();

  return (
    <>
      <div className="fullBg">
        <div className="megaWrap">
          {/* <HeaderWork
      siteTitle={siteTitle}
    /> */}
          <header>
            <span
              style={{ transform: `translateX(${completion - 100}%)` }}
              className="progressBar"
            />
            <div className={myElementIsVisible ? "hidden" : "logoWrap"}>
              <Link className="logoWork" to="/">
                {siteTitle}
              </Link>
            </div>
          </header>
          <Navigation />
          <ResNav />
          <div>{children}</div>
          <span ref={workRef}>
            <Footer siteTitle={siteTitle} />
          </span>
        </div>
      </div>
    </>
  );
};

export default LayoutVideo;
